import { createI18n } from 'vue-i18n'

function loadMessages () {
  const locales = require.context('./messages', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys()
    .forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
  return messages
}

export default createI18n({
  locale: window.locale.replace('_', '-'),
  fallbackLocale: 'en',
  messages: loadMessages()
})
