import { defineStore } from 'pinia'
import { useProductsStore } from '@/stores/products'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    loading: false,
    initialized: false,
    storeFrontAccessToken: window.tx_products.storefront_accesstoken,
    productDetailPage: window.tx_products.productDetailPage,
    loadedProducts: 12
  }),
  getters: {},
  actions: {
    async init ()
    {
      this.loading = true
      const productStore = useProductsStore()
      await productStore.getAllProducts()
      this.loading = false
      this.initialized = true
    }
  }
})
