<template>
  <div class="products__list">
    <div class="products-list-header">
      <div class="products__filter">
        <FilterBar></FilterBar>
      </div>
    </div>
    <div class="products__list--list">
      <ResultList></ResultList>
    </div>
    <div class="products__list--expand">
      <ExpandListButton></ExpandListButton>
    </div>
  </div>
</template>

<script>
import ResultList from './components/ResultList.vue'
import FilterBar from './components/FilterBar.vue'
import ExpandListButton from './components/ExpandListButton.vue'
import { useGlobalStore } from '@/stores'
import {useProductsStore} from "@/stores/products";

export default {
  name: 'App',
  components: {
    ResultList,
    FilterBar,
    ExpandListButton
  },
  created() {
    const globals = useGlobalStore()
    globals.init()
  },
  setup() {
    const products = useProductsStore();
    return {
      products,
    }
  }
}
</script>
