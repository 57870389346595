<template>
  <div class="filter__section form">
    <div class="filter__list">
      <div class="filter__container">
        <span>{{ $t("filter.sort.label") }}: </span>
        <div class="form-group selectbox">
          <div class="input">
            <select
              class="form-control -filled"
              id="product__sort"
              v-model="selectedSorting"
            >
              <option value="UPDATED_AT">{{ $t("filter.sort.relevance") }}</option>
              <option value="TITLE">{{ $t("filter.sort.title") }}</option>
              <option value="PRICE">{{ $t("filter.sort.price") }}</option>
              <option value="PRICEDESC">{{ $t("filter.sort.pricedesc") }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useProductsStore } from "@/stores/products";
import { useFilterStore } from "@/stores/filter";

export default {
  name: "FilterBar",
  computed: {
    selectedSorting: {
      get() {
        return this.filter.selectedSorting;
      },
      set(value) {
        this.filter.selectedSorting = value;
        this.updateProducts();
      },
    },
  },
  setup() {
    const filter = useFilterStore();
    const products = useProductsStore();
    function updateProducts() {
      this.products.getAllProducts();
    }
    return {
      filter,
      products,
      updateProducts,
    };
  },
};
</script>
