<template>
  <div class="expand_list_button" v-if="products.hasNextPage">
    <div class="loading-spinner" v-if="loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="shop-card-button" v-else>
      <a @click="loadAllProducts" class="btn -btn-beta">
        <span>{{ $t('product.list.button.expand') }}</span>
      </a>
    </div>
  </div>
</template>

<script>

import {useProductsStore} from "@/stores/products";
import {useGlobalStore} from "@/stores";

export default {
  name: "ExpandListButton",
  data: function() {
    return {
      loading: false
    };
  },
  methods: {
    async loadAllProducts() {
      this.loading = true;
      const products = useProductsStore();
      const globals = useGlobalStore();
      globals.loadedProducts = globals.loadedProducts + 12;
      await products.getAllProducts();
      // timeout of 500ms so that the user can actually see the loading animation
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(500);
      this.loading = false;
    }
  },
  setup() {
    const products = useProductsStore();
    return {
      products,
    }
  },
};
</script>
