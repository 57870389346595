import { defineStore } from 'pinia'

export const useFilterStore = defineStore('filters', {
  state: () => ({
    selectedTagsQuery: window.listtagsquery,
    selectedTags: window.listtags,
    selectedSorting: 'UPDATED_AT',
    reverseOrder: false
  })
})
