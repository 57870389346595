<template>
  <div>
    <div class="shop_list_by_category_wrapper" v-if="globals.initialized">
      <template v-for="(product, index) in products.products" :key="index">
        <ProductCard :product="product.node"></ProductCard>
      </template>
      <div class="no-results" v-if="products.products.length === 0">
        {{ $t('no-results') }}
      </div>
      <div class="product__card--placeholder"></div>
      <div class="product__card--placeholder"></div>
      <div class="product__card--placeholder"></div>
    </div>
    <div v-else class="shop_list_by_category_wrapper">
      <div class="shop-product-card--loading" v-for="index in 12" :key="index">
        <div class="shop-product-card-image--loading"></div>
        <div class="shop-product-card-content--loading">
          <div class="shop-product-card-title--loading"></div>
          <div class="shop-product-card-title--loading"></div>
          <div class="shop-product-card-title--loading"></div>
          <div class="shop-product-card-meta--loading"></div>
          <div class="shop-product-card-btn--loading"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'
import { useProductsStore } from '@/stores/products'
import { useGlobalStore } from '@/stores'

export default {
  name: 'ResultList',
  components: {
    ProductCard
  },
  setup() {
    const products = useProductsStore();
    const globals = useGlobalStore();
    return {
      products,
      globals
    }
  },
  updated() {
    const interval = setInterval(() =>
    {
      if (typeof gtag === 'function')
      {
        let items = []
        const products = useProductsStore().products;

        products.forEach((element) => {
          const product = element.node
          const productToAdd = {
            "item_id": product.id,
            "item_name": product.title
          }

          items.push(productToAdd)
        })

        // eslint-disable-next-line no-undef
        gtag("event", "view_item_list", {
          item_list_id: "list_by_category",
          item_list_name: "List by category",
          items: items
        });

        clearInterval(interval)
      }
    }, 300)
  }
}
</script>
