<script setup>
import {defineProps} from 'vue'
import {useGlobalStore} from "@/stores"

const global = useGlobalStore()

defineProps({
  product: {
    type: Object,
    default: () => ({})
  }
})

function formatPrice(value) {
  let val = (value/1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
</script>

<template>
  <div v-if="(product) && (product.availableForSale === true) && (product.handle) && (product.priceRange) &&
  (product.priceRange.maxVariantPrice.amount) && (product.priceRange.minVariantPrice.amount) &&
  (product.title) && (product.media.edges[0]) && (product.media.edges[0].node) &&
  (product.media.edges[0].node.previewImage) && (product.media.edges[0].node.previewImage.small) && (product.variants) &&
  (product.variants.edges[0]) && (product.variants.edges[0].node) && (product.variants.edges[0].node.price.amount)"
       class="shop-product-card">
    <a :href="global.productDetailPage + product.handle">
      <div class="product-card-img">
        <div class="img-preloaded"></div>
        <img :src="product.media.edges[0].node.previewImage.small" :alt="product.media.edges[0].node.alt" />
      </div>
      <span class="shop-card-title">{{product.title}}</span>
    </a>
    <div class="product-card-bottom">
      <div class="shop-card-pricing">
        <div v-if="product.priceRange.maxVariantPrice.amount === product.priceRange.minVariantPrice.amount">
          <span class="-color-secondary-alpha">{{formatPrice(product.priceRange.maxVariantPrice.amount)}} €*</span>
        </div>
        <div v-else>
          <span class="-color-secondary-alpha">{{formatPrice(product.priceRange.minVariantPrice.amount)}} - {{formatPrice(product.priceRange.maxVariantPrice.amount)}} €*</span>
        </div>
      </div>
      <div class="shop-card-button">
        <a class="product-overlay-list-button btn -btn-beta-inverted"
           :data-product-title="product.title"
           :data-product-image="product.media.edges[0].node.previewImage.small"
           :data-product-image-alt="product.media.edges[0].node.alt"
           :data-product-handle="product.handle">
          <div v-for="(item, index) of product.variants.edges" :key="index" class="variant"
               :data-descr="item.node.selectedOptions[0].value"
               :data-price="item.node.price.amount"
               :data-id="item.node.id"
          ></div>
          <span class="icon-cart-before"></span>
          <span>{{ $t('product.list.button.linktext') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
