import { defineStore } from 'pinia'
import api from '@/stores/api'
import { useFilterStore } from '@/stores/filter'
import { useGlobalStore } from '@/stores/index'

export const useProductsStore = defineStore('products', {
  state: () => ({
    products: [],
    hasNextPage: false
  }),
  actions: {
    async getAllProducts ()
    {
      const filterStore = useFilterStore()
      let selectedTagsQuery = filterStore.selectedTagsQuery
      if (selectedTagsQuery && selectedTagsQuery !== '') {
        if (selectedTagsQuery.includes('&#039;')) {
          selectedTagsQuery = selectedTagsQuery.replaceAll("&#039;", '"')
        }
        const globals = useGlobalStore()
        let sortKey = filterStore.selectedSorting
        let reverseOrder = filterStore.reverseOrder
        if (sortKey === 'PRICE' || sortKey === 'TITLE') {
          reverseOrder = false
        } else if (sortKey === 'PRICEDESC') {
          sortKey = 'PRICE'
          reverseOrder = true
        } else {
          reverseOrder = true
        }

        globals.loading = true
        const data = JSON.stringify({
          query: `query ProductsByTags($selectedTagsQuery: String, $sortKey: ProductSortKeys, $reverseOrder: Boolean, $loadedProducts: Int) {
                  products(first: $loadedProducts, query: $selectedTagsQuery, sortKey: $sortKey, reverse: $reverseOrder) {
                    edges {
                      node {
                        handle
                        id
                        title
                        availableForSale
                        tags
                        priceRange {
                          maxVariantPrice {
                            amount
                            currencyCode
                          }
                          minVariantPrice {
                            amount
                            currencyCode
                          }
                        }
                        media(first: 1) {
                          edges {
                            node {
                              mediaContentType
                              alt
                              ...mediaFieldsByType
                            }
                          }
                        }
                        variants(first: 5) {
                          edges {
                            node {
                              id
                              price {
                                 amount
                                 currencyCode
                              }
                              priceV2 {
                                amount
                                currencyCode
                              }
                              compareAtPriceV2 {
                                amount
                                currencyCode
                              }
                              requiresShipping
                              selectedOptions {
                                name
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                    pageInfo {
                      hasNextPage
                    }
                  }
        }
        fragment mediaFieldsByType on Media {
          ...on MediaImage {
            image {
              url
            }
            previewImage {
              url
              small: url(transform: { maxHeight: 540, maxWidth: 540, crop: CENTER })
              main: url(transform: { maxHeight: 940, maxWidth: 940, crop: CENTER })
            }
          }
        }`, variables: {selectedTagsQuery: selectedTagsQuery, sortKey: sortKey, reverseOrder: reverseOrder, loadedProducts: 250}
        })
        await api.post('/', data).then(response =>
        {
          let productsArr = response.data.data.products.edges
          let selectedTagsArr = filterStore.selectedTags.split(',')
          let matchingProducts = []
          //default
          this.hasNextPage = false
          for (let i = 0; i < productsArr.length; i++) {
            let matching = true
            if (selectedTagsArr !== null && (selectedTagsArr.length > 1 || selectedTagsArr[0] !== '')) {
              for (let j = 0; j < selectedTagsArr.length; j++) {
                // if product matches so far but the currently looked at selected tag is not in the product tags
                if (matching === true && !(productsArr[i].node.tags.indexOf(selectedTagsArr[j]) >= 0)) {
                  matching = false
                }
              }
            }
            // if tags match and array is not full
            if (matching === true && matchingProducts.length < globals.loadedProducts) {
              matchingProducts.push(productsArr[i])
            }
            // check if loaded products + next element exists and if yes flag has next page to true
            if (matching === true && matchingProducts.length >= globals.loadedProducts) {
              this.hasNextPage = true
              break;
            }
            // if only negating tags else if selecting tags (+ negating) tags
            if (selectedTagsArr === null || (matching === false && selectedTagsArr.length === 1 && selectedTagsArr[0] === '')) {
              this.products = response.data.data.products.edges
            } else if (matchingProducts.length >= 1) {
              this.products = matchingProducts
            } else {
              this.products = ''
            }
          }
          globals.loading = false
        }).catch(error =>
        {
          console.log(error)
        })
      }
    }
  }
})
